const base = process.env.REACT_APP_BASEURL;
const api = process.env.REACT_APP_API_URL;
const dashboardUrl = "/cms";
const authUrl = "/auth";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  promotions: dashboardUrl + "/products/promotions",
  ordersList: dashboardUrl + "/orders",
  adminList: dashboardUrl + "/customers/admins",
  userList: dashboardUrl + "/customers/users",
  customerList: dashboardUrl + "/companies/all",
  snelstartCustomerList: dashboardUrl + "/companies/snelstart",
  customerListPending: dashboardUrl + "/companies/pending",
  users: dashboardUrl + "/admin/users",
  notifications: dashboardUrl + "/notifications",
  reports: dashboardUrl + "/reports",
  createSnelstartRelatie: api + "/api/snelstart/relatie",
  getAllSnelstartRelaties: api + "/api/snelstart/relaties",
  sendCustomerRequestRejectedMail: api + "/api/mail/customer_request/rejected",
};
