import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig"
import urls from "urls";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={urls.login} component={lazy(() => import(`./authentication/login`))} />
        <Route path={urls.forgotPassword} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${urls.acceptInvite}:encodedLink`} component={lazy(() => import(`./authentication/accept-invite`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Redirect from={urls.auth} to={urls.login} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

