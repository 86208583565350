import {
  FileTextOutlined,
  LineChartOutlined,
  NotificationOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import i18n from "translations/i18n";
import urls from "urls";

const shopNavTree = [
  {
    key: "shop",
    title: i18n.t("shop"),
    breadcrumb: false,
    submenu: [
      {
        key: "shop-reports",
        path: urls.reports,
        title: i18n.t("reports"),
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-products",
        title: i18n.t("products"),
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-products-productList",
            path: urls.productList,
            title: i18n.t("products"),
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "shop-products-addProduct",
            path: urls.addProduct,
            title: i18n.t("add_product"),
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-orders",
        path: urls.ordersList,
        title: i18n.t("order.orders"),
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-companies-existing",
        path: urls.customerList,
        title: i18n.t("customers_all"),
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-notifications",
        path: urls.notifications,
        title: i18n.t("notifications_title"),
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-customers",
        path: urls.userList,
        title: i18n.t("users"),
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-users",
            path: urls.userList,
            title: i18n.t("shop_users"),
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-admin",
            path: urls.adminList,
            title: i18n.t("admin_users"),
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree];

export default navigationConfig;
